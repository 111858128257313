<template>
  <ClientOnly>
    <div class="card-list">
      <div class="card-list__row grid__row">
        <div
          v-for="(card, idx) in cards"
          :key="idx"
          class="card-list__column grid__column grid__column--12"
        >
          <component
            :is="card.data.to ? 'router-link' : 'div'"
            :to="card.data.to ? card.data.to : undefined"
            class="card-list__card"
          >
            <component :is="getCardComponent(card.type)" v-bind="card.data" />
          </component>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { defineComponent, defineProps, PropType } from 'vue'

import ClientOnly from '@base/components/ClientOnly/ClientOnly.vue'
import CardHole from '@base/components/CardHole/CardHole.vue'
import CardEvent from '@base/components/CardEvent/CardEvent.vue'
import CardListingCourse from '@base/components/CardListingCourse/CardListingCourse.vue'
import CardListingEvent from '@base/components/CardListingEvent/CardListingEvent.vue'
import CardResourceGallery from '@base/components/CardResourceGallery/CardResourceGallery.vue'
import CardResourceText from '@base/components/CardResourceText/CardResourceText.vue'
import CardResourceVideo from '@base/components/CardResourceVideo/CardResourceVideo.vue'

import { Card, CardType } from '@base/_types/Card'

defineComponent({
  name: 'CardList',
  components: {
    CardHole,
    CardEvent,
    CardListingCourse,
    CardListingEvent,
    CardResourceGallery,
    CardResourceText,
    CardResourceVideo
  }
})

const props = defineProps({
  cards: {
    type: Array as PropType<Card[]>,
    required: true
  }
})

const getCardComponent = (type: CardType) => {
  switch (type) {
    case CardType.CardEvent:
      return CardEvent
    case CardType.CardHole:
      return CardHole
    case CardType.CardListingCourse:
      return CardListingCourse
    case CardType.CardListingEvent:
      return CardListingEvent
    case CardType.CardResourceGallery:
      return CardResourceGallery
    case CardType.CardResourceText:
      return CardResourceText
    case CardType.CardResourceVideo:
      return CardResourceVideo
  }
}
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

.card-list {
  &__column {
    display: block;
    margin-top: var(--spacing--6);

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
