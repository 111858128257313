import { gql } from '@apollo/client/core'

import { ATTRIBUTE_FRAGMENT } from './attribute'

export const CLUB_FRAGMENT = gql`
  ${ATTRIBUTE_FRAGMENT}
  fragment Club on Club {
    id
    clubName
    slug
    createdAt
    activated
    addressLine1
    addressLine2
    city
    province
    postalCode
    advancedTeeTimeBooking
    advancedEventBooking
    country
    addressGeom {
      lat
      lon
    }
    phoneNumber
    email
    website
    description
    logo
    gallery
    galleryPaths
    amenities {
      ...Attribute
    }
    bookingInstructions
    gettingStarted
    dressCode
    averageRating
    reviewsCount
    users {
      ... on ListUsersOutput {
        count
        users {
          id
          profilePicture
          firstName
          lastName
          email
        }
      }
    }
    isNonGolf
    customAmenities
  }
`
